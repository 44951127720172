.page-content-kerko {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 30px;
  padding: 0 130px;
}

.page-content-kerko.rregullorja {
  grid-template-columns: 2fr 1fr;
}

.page-content-kerko.biblioteka {
  grid-template-columns: 2fr 1fr;
}

.left-content-kerko {
  padding-left: 10px;
  padding-right: 30px;
  text-align: left;
}

.right-content-kerko {
  padding-left: 30px;
  padding-right: 10px;
}

.udhezues ul {
  margin-top: 30px;
  list-style-type: none;
}

.udhezues ul li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
  color: #333333;
  font-family: "Manrope", serif;
  transition: all 0.12s;
  text-decoration: none;
}

.udhezues ul li a {
  color: #333333;
  font-family: "Manrope", serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3em;
  text-decoration: none;
  transition: all 0.12s;
}

.udhezues ul li a:visited {
  color: #333333;
  text-decoration: none;
}

.udhezues ul li:before {
  content: "—";
  position: absolute;
  left: 0;
  font-weight: bold;
  color: #333333;
}

.udhezues ul li a:hover {
  color: red;
}

.udhezues ul li button {
  border: none;
  background-color: transparent;
  color: #999999;
  font-family: "Manrope", serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3em;
  text-decoration: none;
  transition: all 0.12s;
  cursor: pointer;
  text-align: left;
}

.udhezues ul li button:hover {
  color: red;
}

.rregullorja .subtitle {
  font-size: 18px;
  font-family: "Lexend", serif;
  line-height: 30px;
  color: #333333;
  margin-bottom: 10px;
}

.neni {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
}

.neni .title {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 26px;
  font-weight: 700;
  font-family: "Work Sans", serif;
  line-height: 30px;
  color: #333333;
}

.neni .item-subtitle {
  font-style: italic;
  font-family: "Manrope", serif;
}

.neni ol {
  list-style-type: decimal;
  padding-left: 20px;
  margin-bottom: 10px;
}

.neni ol ul {
  padding-left: 20px;
}

.neni ol li {
  list-style-type: decimal;
  font-size: 18px;
  font-family: "Manrope", serif;
  line-height: 25px;
  color: #333333;
}

.neni ol ul {
  list-style-type: disc;
  margin-left: 40px;
  padding-left: 20px;
}

.neni ol ul li {
  list-style-type: disc;
  font-family: "Manrope", serif;
}

.right-content-kerko .neni-doc ul {
  list-style-type: none;
}

.right-content-kerko .neni-doc ul li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
  color: #333333;
  font-family: "Manrope", serif;
  transition: all 0.12s;
  text-decoration: none;
  text-align: left;
}

.right-content-kerko .neni-doc ul li a {
  color: #333333;
  font-family: "Manrope", serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3em;
  text-decoration: none;
  transition: all 0.12s;
  text-align: left;
}

.right-content-kerko .neni-doc ul li a:visited {
  color: #333333;
  text-decoration: none;
}

.right-content-kerko .neni-doc ul li:before {
  content: "—";
  position: absolute;
  left: 0;
  font-weight: bold;
  color: #333333;
}

.right-content-kerko .neni-doc ul li a:hover {
  color: red;
}

.other-content {
  color: #333;
  text-align: justify;
  font-family: "Manrope", serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.left-content-kerko .biblioteka {
  color: #333;
  text-align: justify;
  font-family: "Manrope", serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.left-content-kerko .fototeka {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

.left-content-kerko .fototeka p {
  color: #333;
  text-align: justify;
  font-family: "Manrope", serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.left-content-kerko .fonoteka {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

.left-content-kerko .fonoteka p {
  color: #333;
  text-align: justify;
  font-family: "Manrope", serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

@media (width<=1500px) {
  .page-content-kerko {
    padding: 0 100px;
  }
}

@media (width<=1260px) {
  .page-content-kerko {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 50px;
  }

  .left-content-kerko {
    width: 100%;
    padding: 0;
  }
}

@media (width<=1300px) {
  .page-content-kerko.rregullorja {
    padding: 0 50px;
  }
}

@media (width<=1250px) {
  .page-content-kerko {
    padding: 0 50px;
  }
}

@media (width<=992px) {
  .page-content-kerko {
    padding: 0 20px;
  }

  .page-content-kerko.rregullorja {
    padding: 0 20px;
  }
}
