.page-content.privatesise {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 130px;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.section-title {
  margin-top: 40px;
  margin-bottom: 10px;
  font-size: 28px;
  font-weight: 700;
  font-family: "Lexend", serif;
  line-height: 36px;
  color: #333;
  text-align: left;
}

.section-content p,
.subsection-content p {
  color: #333;
  font-family: "Manrope", serif;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 10px;
  text-align: justify;
}

.section-content ul {
  list-style-type: disc;
  margin-top: 0;
  margin-bottom: 10px;
  padding-left: 40px;
  text-align: justify;
}

.section-content ul li {
  list-style-type: disc;
  font-family: "Manrope", serif;
}

.subsection-title {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 700;
  font-family: "Manrope", serif;
  line-height: 30px;
  color: #333;
  text-align: justify;
}

@media (width<=1450px) {
  .page-content.privatesise {
    padding: 0 100px;
  }
}

@media (width<=1250px) {
  .page-content.privatesise {
    padding: 0 50px;
  }
}

@media (width<=992px) {
  .page-content.privatesise {
    padding: 0 20px;
  }
}
