.regjistri-rkh {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 0 130px;
  text-align: left;
  margin: 30px 0;
}

.tabela-rigjetjes {
  display: grid;
  grid-template-columns: 4fr 1fr;
  gap: 50px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 100px;
  text-align: left;
  margin: 30px 0;
  overflow-y: auto;
}

.table-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.regjistri-rkh .table-container .grid-row {
  grid-template-rows: auto auto;
  grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 3fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.regjistri-rkh .table-container .grid-row div {
  font-family: "Manrope", serif;
}

.tabela-rigjetjes .table-container .grid-row {
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 3fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
  width: 100%;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.tabela-rigjetjes .table-container .grid-row:last-child {
  border-bottom: none;
}

.table-container .grid-row.grid-header {
  width: 100%;
  margin-bottom: 10px;
  padding-bottom: 10px;
  font-family: "Manrope", serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #333;
  border-bottom: 10px solid #dddddd;
}

.table-container .grid-row.rows {
  border-bottom: 1px solid #dddddd;
}

.table-container .grid-row.rows:last-child {
  border-bottom: none;
}

.table-container .row-content {
  width: 100%;
  box-sizing: border-box;
  padding: 10px 0;
  border-bottom: 1px solid #dddddd;
}

.table-container .grid-row.grid-header div,
.table-container .row-content .grid-row.rows div {
  padding: 0 7px;
  font-family: "Lexend", serif;
}

.grid-row.rows div {
  font-family: "Manrope", serif;
}

.table-container .row-content .grid-row.rows div {
  font-size: 16px;
  line-height: 22px;
  color: #333;
}

.tabela-rigjetjes a.download-link {
  background-color: #db0606;
  color: #fff;
  font-family: "Manrope", serif;
  transition: all 0.12s;
  padding: 10px 8px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  height: max-content;
}

.tabela-rigjetjes a.download-link:visited {
  color: #fff;
}

.tabela-rigjetjes a.download-link:hover {
  background-color: #333;
}

.page-content-manual {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 130px;
  text-align: left;
  margin: 30px 0;
}

.page-content-manual .left-content-manual {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 40px;
  padding-left: 10px;
  padding-right: 30px;
}

.page-content-manual .left-content-manual p {
  color: #333;
  text-align: justify;
  font-family: "Manrope", serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.page-content-manual .left-content-manual a {
  background-color: #db0606;
  color: #fff;
  font-family: "Manrope", serif;
  transition: all 0.12s;
  padding: 10px 8px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  height: max-content;
}

.page-content-manual .left-content-manual a:hover {
  background-color: #333;
}

.page-content-manual .left-content-manual a:visited {
  color: #fff;
}

.page-content-manual .right-content-manual {
  padding-left: 30px;
  padding-right: 10px;
}

@media (width<=1450px) {
  .regjistri-rkh,
  .page-content-manual {
    padding: 0 100px;
  }
}

@media (width<=1250px) {
  .regjistri-rkh,
  .page-content-manual {
    padding: 0 50px;
  }

  .tabela-rigjetjes {
    padding: 0 50px;
  }
}

@media (width<= 1100px) {
  .regjistri-rkh .table-container .grid-row {
    display: flex;
    flex-direction: column;
    border: 1px solid #dddddd;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
  }

  .regjistri-rkh .table-container .grid-row div {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    border-bottom: 1px solid #f1f1f1;
  }

  .regjistri-rkh .table-container .grid-row div:last-child {
    border-bottom: none;
  }

  .regjistri-rkh .table-container .grid-row div::before {
    content: attr(data-label);
    font-weight: bold;
    margin-right: 10px;
    font-family: "Manrope", serif;
  }

  .regjistri-rkh .table-container .grid-row.grid-header {
    display: none;
  }

  .tabela-rigjetjes {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
  }

  .tabela-rigjetjes .table-container {
    width: 100%;
  }

  .tabela-rigjetjes .table-container .grid-row {
    display: flex;
    flex-direction: column;
    border: 1px solid #dddddd;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
  }

  .tabela-rigjetjes .table-container .grid-row div {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    border-bottom: 1px solid #f1f1f1;
  }

  .tabela-rigjetjes .table-container .grid-row div:last-child {
    border-bottom: none;
  }

  .tabela-rigjetjes .table-container .grid-row div::before {
    content: attr(data-label);
    font-weight: bold;
    margin-right: 10px;
  }

  .tabela-rigjetjes .table-container .grid-header {
    display: none;
  }

  .page-content-manual {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
  }

  .page-content-manual .left-content-manual,
  .page-content-manual .right-content-manual {
    padding: 0;
  }
}

@media (width<=992px) {
  .regjistri-rkh,
  .page-content-manual {
    padding: 0 20px;
  }

  .tabela-rigjetjes {
    padding: 0 20px;
  }
}
