.page-content-legjislacioni {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 30px;
  padding: 0 130px;
}

.page-content-legjislacioni.ligje {
  grid-template-columns: 1.5fr 1fr;
}

.page-content-legjislacioni.dokumente {
  grid-template-columns: 1fr 1fr;
}

.left-content-legjislacioni {
  padding-left: 10px;
  padding-right: 30px;
}

.right-content-legjislacioni {
  padding-left: 30px;
  padding-left: 10px;
}

.left-content-legjislacioni ul,
.right-content-legjislacioni ul {
  list-style-type: none;
  margin-bottom: 30px;
  margin-top: 10px;
}

.left-content-legjislacioni ul li,
.right-content-legjislacioni ul li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
  color: #333333;
  font-family: "Manrope", serif;
  transition: all 0.12s;
  text-decoration: none;
  text-align: left;
}

.left-content-legjislacioni ul li a,
.right-content-legjislacioni ul li a {
  color: #333333;
  font-family: "Manrope", serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3em;
  text-decoration: none;
  transition: all 0.12s;
}

.left-content-legjislacioni ul li a:visited,
.right-content-legjislacioni ul li a:visited {
  color: #333333;
  text-decoration: none;
}

.left-content-legjislacioni ul li:before,
.right-content-legjislacioni ul li:before {
  content: "—";
  position: absolute;
  left: 0;
  font-weight: bold;
  color: #333333;
}

.left-content-legjislacioni ul li a:hover,
.right-content-legjislacioni ul li a:hover {
  color: red;
}

.left-content-legjislacioni ul li p {
  font-size: 18px;
  font-family: "Manrope", serif;
}

.left-content-legjislacioni ul li a ol,
.left-content-legjislacioni ul li a ol li {
  list-style-type: none;
}

.left-content-legjislacioni ul li p a {
  color: #0000ee;
  font-family: "Manrope", serif;
}

.left-content-legjislacioni ul li p a:visited {
  color: #551a8b;
}

.right-content-title {
  font-size: 18px;
  font-weight: 700;
  font-family: "Lexend", serif;
  line-height: 24px;
  color: #333333;
  text-align: left;
}

@media (width<=1450px) {
  .page-content-legjislacioni {
    padding: 0 100px;
  }
}

@media (width<=1250px) {
  .page-content-legjislacioni {
    padding: 0 50px;
  }
}

@media (width<=1100px) {
  .page-content-legjislacioni {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;
  }
}

@media (width<=992px) {
  .page-content-legjislacioni {
    padding: 0 20px;
  }

  .left-content-legjislacioni,
  .right-content-legjislacioni {
    padding: 0;
  }
}
