.page {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
}

.page-header {
  width: 100%;
  padding: 40px 130px 50px;
  background-color: #f7f7f7;
  box-sizing: border-box;
  display: flex;
}

.page-title {
  font-size: 38px;
  font-weight: 600;
  font-family: "Lexend", serif;
  color: black;
  display: inline-block;
  text-align: left;
}

.headerTitleLine {
  background-color: #131313;
  border-radius: 0;
  width: 5px;
  height: 40px;
  margin-right: 15px;
  display: inline-block;
}

.page-content-historiku {
  padding: 0 130px;
  margin: 30px 0;
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.left-content-historiku {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  float: left;
  width: 100%;
  position: relative;
}

.left-content-historiku p {
  color: #333;
  text-align: justify;
  font-family: "Manrope", serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.right-content-historiku {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  float: left;
  width: 100%;
  position: relative;
}

.right-content-historiku button {
  border: none;
  background-color: red;
  color: #fff;
  padding: 8px 25px;
  font-family: "Manrope", serif;
  font-size: 18px;
  display: inline-block;
  cursor: pointer;
}

.left-content-historiku,
.right-content-historiku {
  padding-left: 10px;
  padding-right: 30px;
}

.modal-post-historiku {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content-post-historiku {
  max-width: 80%;
  max-height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content-post-historiku img {
  max-width: 100%;
  max-height: calc(100vh - 60px);
  object-fit: contain;
}

.modal-close-historiku {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 2rem;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
}

.modal-prev-post-historiku,
.modal-next-post-historiku {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
}

.modal-prev-post-historiku {
  left: 10px;
}

.modal-next-post-historiku {
  right: 10px;
}

.modal-prev-historiku:hover,
.modal-next-historiku:hover,
.modal-close-historiku:hover {
  color: #ccc;
}

.page-content-kla {
  padding: 0 130px;
  margin: 30px 0;
  display: grid;
  grid-template-columns: 2fr 1fr;
  justify-content: flex-start;
  align-items: flex-start;
}

.left-content-kla,
.right-content-kla {
  padding-left: 10px;
  padding-right: 30px;
}

.left-content-kla {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  position: relative;
  text-align: justify;
}

.left-content-kla p {
  color: #333;
  font-family: "Manrope", serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.left-content-kla ul {
  list-style-type: none;
  counter-reset: list-counter;
  text-align: left;
}

.left-content-kla ul li {
  counter-increment: list-counter;
  font-family: "Manrope", serif;
  color: #333;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.left-content-kla ul li::before {
  content: counter(list-counter, lower-alpha) ") ";
}

.right-content-kla .council-member {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin-bottom: 40px;
}

.right-content-kla .council-member .member-photo img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: fill;
}

.right-content-kla .council-member .member-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.right-content-kla .council-member .member-info .name {
  color: #333;
  font-family: "Manrope", serif;
  font-size: 20px;
  text-decoration: none;
}

.right-content-kla .council-member .member-info .info.member {
  color: #7c6b6b;
  margin-top: 3px;
  font-family: "Manrope", serif;
  font-size: 16px;
}

.right-content-kla .council-member .member-info .other-info {
  color: #7c6b6b;
  margin-top: 6px;
  font-family: "Manrope", serif;
  font-size: 12px;
  text-align: left;
}

.page-content-ra {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: flex-start;
  padding: 0 130px;
  margin: 30px 0;
}

.left-content-ra {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  position: relative;
  text-align: justify;
  padding-left: 10px;
  padding-right: 30px;
}

.left-content-ra p {
  color: #333;
  text-align: justify;
  font-family: "Manrope", serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.right-content-ra {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 60px;
  padding-right: 10px;
  padding-left: 30px;
}

.right-content-ra .rrjeti-arkivor {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
}

.right-content-ra .rrjeti-arkivor .right-title {
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Work Sans", serif;
}

.right-content-ra .rrjeti-arkivor .right-subtitle {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  font-family: "Work Sans", serif;
}

.right-content-ra .rrjeti-arkivor ul {
  margin-top: 30px;
  list-style-type: none;
}

.right-content-ra .rrjeti-arkivor ul li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
  color: #333333;
  font-family: "Manrope", serif;
  transition: all 0.12s;
  text-decoration: none;
}

.right-content-ra .rrjeti-arkivor ul li a {
  color: #333333;
  font-family: "Manrope", serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3em;
  text-decoration: none;
  transition: all 0.4s ease-in-out;
}

.right-content-ra .rrjeti-arkivor ul p {
  color: #333333;
  font-family: "Manrope", serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3em;
}

.right-content-ra .rrjeti-arkivor ul li a:visited {
  color: #333333;
  text-decoration: none;
}

.right-content-ra .rrjeti-arkivor ul li a:hover {
  color: red;
}

.right-content-ra .rrjeti-arkivor ul li:before {
  content: "—";
  position: absolute;
  left: 0;
  font-weight: bold;
  color: #333333;
}

.page-content-struktura {
  box-sizing: border-box;
  padding: 0 130px;
  text-align: left;
  margin: 30px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.right-content-struktura {
  font-family: "Manrope", serif;
  font-weight: 600;
  margin-top: 120px;
  padding-left: 50px;
}

.struktura-item {
  margin-bottom: 40px;
  font-family: "Manrope", serif;
}

.left-content-struktura .struktura-item {
  margin-bottom: 70px;
  font-family: "Manrope", serif;
  font-weight: 600;
}

.struktura-item ul {
  list-style-type: none;
  margin-bottom: 30px;
  margin-top: 10px;
}

.struktura-item ul li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
  color: #333333;
  font-family: "Manrope", serif;
  transition: all 0.12s;
  text-decoration: none;
}

.struktura-item ul li a {
  color: #333333;
  font-family: "Manrope", serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3em;
  text-decoration: none;
  transition: all 0.12s;
}

.struktura-item ul li a:visited {
  color: #333333;
  text-decoration: none;
}

.struktura-item ul li:before {
  content: "—";
  position: absolute;
  left: 0;
  font-weight: bold;
  color: #333333;
}

.struktura-item ul li a:hover {
  color: red;
}

.struktura-item .list-title {
  font-size: 24px;
  font-weight: 700;
  font-family: "Manrope", serif;
  line-height: 30px;
  color: #333333;
  cursor: pointer;
}

.left-content-struktura .struktura-item .list-title.underline,
.right-content-struktura .struktura-item .list-title {
  text-decoration: underline;
  font-family: "Work Sans", serif;
  line-height: 32px;
}

.left-content-struktura .struktura-item .list-title.none,
.right-content-struktura .struktura-item .list-title.none {
  text-decoration: none;
  cursor: default;
  font-family: "Work Sans", serif;
}

.left-content-struktura .struktura-item .list-title:hover,
.right-content-struktura .struktura-item .list-title:hover {
  color: red;
}

.left-content-struktura .struktura-item .list-title.none:hover,
.right-content-struktura .struktura-item .list-title.none:hover {
  cursor: default;
  color: #333333;
}

.page-content-sektori {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 130px;
  margin-top: 30px;
}

.left-content-sektori {
  float: left;
  width: 100%;
  padding-left: 10px;
  padding-right: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.left-content-sektori .image {
  object-fit: cover;
  object-position: 50% 20%;
  width: 200px;
  height: 200px;
  margin-top: -55px;
  margin-bottom: 20px;
  position: relative;
}

.left-content-sektori .name {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
}

.left-content-sektori .sektori-content {
  color: #333;
  text-align: justify;
  font-family: "Manrope", serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
}

.left-content-sektori .sektori-content .directors {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 40px;
}

.left-content-sektori .sektori-content .directors .director {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.left-content-sektori .sektori-content .directors .director img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.left-content-sektori
  .sektori-content
  .directors
  .director
  .director-info
  .name {
  color: #333;
  font-family: "Manrope", serif;
  font-size: 20px;
  text-decoration: none;
  margin: 0;
}

.left-content-sektori
  .sektori-content
  .directors
  .director
  .director-info
  .period {
  color: #7c6b6b;
  margin: 0;
  font-family: "Manrope", serif;
  font-size: 12px;
  text-align: left;
  line-height: 18px;
}

.right-content-sektori {
  padding-left: 30px;
  padding-right: 10px;
}

@media (width<=1450px) {
  .page-header {
    padding: 40px 100px 50px;
  }

  .page-content-historiku {
    padding: 0 100px;
    grid-template-columns: 3fr 1fr;
  }

  .page-content-struktura {
    padding: 0 100px;
  }

  .page-content-ra {
    padding: 0 100px;
  }

  .page-content-sektori {
    padding: 0 100px;
  }
}

@media (width<=1400px) {
  .page-content-kla {
    padding: 0 100px;
  }
}

@media (width<=1250px) {
  .page-header {
    padding: 40px 50px 50px;
  }

  .page-content-historiku {
    padding: 0 50px;
    grid-template-columns: 4fr 1fr;
  }

  .page-content-struktura {
    padding: 0 50px;
  }

  .page-content-ra {
    padding: 0 50px;
  }

  .page-content-kla {
    padding: 0 50px;
  }

  .page-content-sektori {
    padding: 0 50px;
  }
}

@media (width<=1200px) {
  .page-content-ra {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;
  }
}

@media (width<=1080px) {
  .page-content-struktura {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .right-content-struktura {
    margin-top: 0;
    padding-left: 0;
  }

  .right-content-struktura .struktura-item {
    margin-bottom: 70px;
  }

  .page-content-sektori {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (width<=992px) {
  .page-header {
    padding: 40px 20px 50px;
  }

  .page-content-historiku {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
  }

  .page-content-struktura {
    padding: 0 20px;
  }

  .page-content-kla {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
  }

  .page-content-ra {
    padding: 0 20px;
  }

  .right-content-kla {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
    gap: 20px;
    padding: 0;
  }

  .page-content-sektori {
    padding: 0 20px;
  }

  .left-content-historiku,
  .left-content-kla,
  .left-content-ra,
  .right-content-ra {
    padding: 0;
  }
}

@media (width<=680px) {
  .right-content-kla {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 0;
  }
}

@media (width<=600px) {
  .left-content-sektori .sektori-content .directors {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;
  }
}
