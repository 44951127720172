.manage-user-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
  background-color: white;
  padding: 30px;
}

.manage-user-container .profile-input-box {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}

.manage-user-container .profile-input-box label {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 5px;
  display: block;
  color: #006d77;
}

.manage-user-container .profile-input-box label::after {
  content: " *";
  color: red;
}

.manage-user-container .profile-input-box input {
  width: 70%;
  height: 45px;
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 20px;
  padding-left: 15px;
  box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.1);
  background-color: #f6f8fa;
  font-family: "Poppins", sans-serif;
  transition: all 120ms ease-out 0s;
}

.manage-user-container .profile-input-box input:focus,
.manage-user-container .profile-input-box input:valid {
  box-shadow: 0px 0px 0px 2px #ac8ece;
}

.manage-user-container .profile-input-box:last-child {
  justify-content: center;
}

.manage-user-container .save-btn {
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.manage-user-container button.save-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 20px;
  font-weight: 600;
  padding: 10px 20px;
  margin-top: 30px;
  cursor: pointer;
  background: linear-gradient(to right, #f37a65, #d64141);
  border-radius: 5px;
  color: #ffffff;
  letter-spacing: 1px;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
}

.manage-user-container button.save-btn:hover {
  background: linear-gradient(to right, #d64141, #f37a65);
}

@media (width <= 890px) {
  .manage-user-container .input-box {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .manage-user-container .input-box input {
    width: 500px;
  }
}

@media (width <= 750px) {
  .manage-user-container .input-box input {
    width: 400px;
  }
}

@media (width <= 635px) {
  .manage-user-container .input-box input {
    width: 350px;
  }
}

@media (width <= 560px) {
  .manage-user-container .input-box input {
    width: 300px;
  }
}

@media (width <= 510px) {
  .manage-user-container .input-box input {
    width: 250px;
  }
}

@media (width <= 455px) {
  .manage-user-container .input-box input {
    width: 200px;
  }
}
