* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Manrope", serif;
  text-decoration: none;
  list-style: none;
}

.body-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: linear-gradient(
    -225deg,
    #5d9fff 0%,
    #b8dcff 48%,
    #6bbbff 100%
  );
}

.container {
  position: relative;
  width: 1000px;
  height: 550px;
  background: #fff;
  margin: 20px;
  border-radius: 30px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  padding-top: 0;
}

.container h1 {
  font-size: 48px;
  font-family: "Work Sans", serif;
  margin: -10px 0;
}

.container p {
  font-size: 16px;
  margin: 15px 0;
}

.login-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-box {
  position: absolute;
  right: 0;
  width: 50%;
  height: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  color: #333;
  text-align: center;
  padding: 40px;
  z-index: 1;
  transition: 0.6s ease-in-out 1.2s, visibility 0s 1s;
}

.container.active .form-box {
  right: 50%;
}

.form-box.register {
  visibility: hidden;
}
.container.active .form-box.register {
  visibility: visible;
}

.input-box {
  width: 100%;
  position: relative;
  margin: 30px 0;
}

.input-box.pass {
  margin-bottom: 10px;
}

.input-box input {
  width: 90%;
  padding: 13px 50px 13px 20px;
  background: #eee;
  border-radius: 8px;
  border: none;
  outline: none;
  font-size: 18px;
  color: #333;
  font-weight: 500;
}

.input-box input::placeholder {
  color: #888;
  font-weight: 400;
}

.toggle-password {
  outline: none;
  border: none;
  background-color: transparent;
  position: relative;
  left: -30px;
}

.toggle-password i.fa-eye {
  font-size: 16px;
}

.forgot {
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  font-family: "Lexend", serif;
  color: #023e8a;
  text-align: center;
  margin-bottom: 20px;
}

.btn {
  width: 90%;
  height: 48px;
  background: #6eacff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: none;
  cursor: pointer;
  font-size: 20px;
  font-family: "Lexend", serif;
  color: #fff;
  font-weight: 600;
  transition: all 0.4s ease-in-out;
}

.btn:hover {
  background: #5d9fff;
}

.toggle-box {
  position: absolute;
  width: 100%;
  height: 100%;
}

.toggle-box::before {
  content: "";
  position: absolute;
  left: -250%;
  width: 300%;
  height: 100%;
  background: #4b93ff;
  border-radius: 150px;
  z-index: 2;
  transition: 1.8s ease-in-out;
}

.container.active .toggle-box::before {
  left: 50%;
}

.toggle-panel {
  position: absolute;
  width: 50%;
  height: 100%;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  transition: 0.6s ease-in-out;
}

.toggle-panel.toggle-left {
  left: 0;
  transition-delay: 1.2s;
}
.container.active .toggle-panel.toggle-left {
  left: -50%;
  transition-delay: 0.6s;
}

.toggle-panel.toggle-right {
  right: -50%;
  transition-delay: 0.6s;
}
.container.active .toggle-panel.toggle-right {
  right: 0;
  transition-delay: 1.2s;
}

.toggle-panel img {
  width: auto;
  height: 250px;
}

@media (width<=1300px) {
  .container {
    width: 900px;
    height: 500px;
  }

  .container h1 {
    font-size: 42px;
  }
}

@media (width<=1150px) {
  .container {
    width: 800px;
    height: 500px;
  }
}

@media (width<=992px) {
  .container {
    height: calc(80vh - 40px);
    width: 550px;
  }

  .container h1 {
    font-size: 32px;
  }

  .form-box {
    bottom: 0;
    width: 100%;
    height: 80%;
    padding-top: 0;
  }

  .login-box {
    padding-top: 30px;
  }

  .toggle-panel img {
    height: 100px;
  }

  .container.active .form-box {
    right: 0;
    bottom: 30%;
  }

  .toggle-box::before {
    left: 0;
    top: -270%;
    width: 100%;
    height: 300%;
    border-radius: 20vw;
  }

  .container.active .toggle-box::before {
    left: 0;
    top: 70%;
  }

  .container.active .toggle-panel.toggle-left {
    left: 0;
    top: -30%;
  }

  .toggle-panel {
    width: 100%;
    height: 30%;
    flex-direction: row;
  }

  .toggle-box::before {
    border-radius: 20px;
    height: 292%;
  }

  .toggle-panel.toggle-left {
    top: 0;
    height: 20%;
  }

  .toggle-panel.toggle-right {
    right: 0;
    bottom: -30%;
  }

  .container.active .toggle-panel.toggle-right {
    bottom: 0;
  }

  .toggle-password {
    left: -40px;
    top: 1px;
  }

  .input-box input,
  .btn {
    font-size: 22px;
    width: 80%;
  }
}

@media (width<=800px) {
  .container {
    width: 500px;
  }
}

@media (width<=690px) {
  .container {
    width: 450px;
  }

  .container h1 {
    font-size: 32px;
  }

  .form-box {
    padding-top: 30px;
  }

  .toggle-panel img {
    height: 100px;
  }

  .input-box input,
  .btn {
    width: 100%;
  }

  .toggle-password {
    left: 150px;
    top: -37px;
  }
}

@media (width<=600px) {
  .container {
    width: 400px;
  }

  .toggle-password {
    left: 140px;
  }
}

@media (width<=550px) {
  .container {
    width: 350px;
  }

  .toggle-panel img {
    height: 150px;
  }

  .container h1 {
    font-size: 26px;
  }

  .toggle-password {
    left: 100px;
  }
}

@media (width<=440px) {
  .toggle-panel {
    flex-direction: column-reverse;
    height: fit-content;
    margin-bottom: 10px;
    box-sizing: border-box;
  }

  .container h1 {
    padding: 0 10px;
  }

  .form-box {
    padding-top: 30px;
  }

  .toggle-box {
    padding: 0 10px;
    box-sizing: border-box;
  }

  .toggle-box::before {
    height: 295%;
  }

  .toggle-panel img {
    height: 100px;
  }
}

@media (width<=400px) {
  .container {
    height: calc(80vh - 40px);
  }
  .form-box {
    padding: 20px;
  }
}

@media (width<=350px) {
  .toggle-password {
    left: 90px;
  }
}
