.page-content.restaurimi {
  padding: 0 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.page-content.restaurimi p {
  text-align: justify;
  margin-bottom: 24px;
  font-size: 18px;
  line-height: 28px;
  color: #333;
  font-family: "Manrope", serif;
}

@media (width<=1450px) {
  .page-content.restaurimi {
    padding: 0 220px;
  }
}

@media (width<=1250px) {
  .page-content.restaurimi {
    padding: 0 170px;
  }
}

@media (width<=992px) {
  .page-content.restaurimi {
    padding: 0 100px;
  }
}

@media (width<=870px) {
  .page-content.restaurimi {
    padding: 0 50px;
  }
}

@media (width<=670px) {
  .page-content.restaurimi {
    padding: 0 20px;
  }
}
