.footer {
  text-align: center;
  background-color: #f6f6f6;
  border-top: 1px solid #eee;
  align-items: flex-start;
  margin-top: 40px;
  padding-top: 60px;
  padding-bottom: 40px;
  position: relative;
  border-bottom: 5px solid #fa8b28;
}

.footer p {
  margin-bottom: 15px;
}

.footer a {
  color: #000;
  text-decoration: none;
}

.footer a:visited {
  color: #000;
}
