.layout {
  width: 100%;
  height: calc(100vh - 75px);
  overflow-y: hidden;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
  background-color: #eeeeee;
  padding: 30px;
}

.manage-content {
  height: 100%;
  flex-grow: 1;
  overflow-y: auto;
  box-sizing: border-box;
  border-radius: 10px;
}

.manage {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  padding-bottom: 30px;
}

.manage-page {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.return {
  display: flex;
  align-items: flex-start;
}

.return button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.return button i {
  font-size: 20px;
  color: #003459;
}

.manage p {
  font-size: 48px;
  font-weight: 600;
  color: #941b0c;
}

.manage-page-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
  margin-bottom: 30px;
  background-color: #fff;
  border-radius: 10px;
}

.manage-page-header-actions {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.manage-page-header p {
  width: 100%;
  font-size: 36px;
  font-weight: 600;
  font-family: "Work Sans", serif;
  color: #780000;
  line-height: 50px;
}

.manage-page-header-actions input {
  width: 250px;
  font-size: 16px;
  padding: 5px 10px;
  font-weight: 500;
  font-family: "Lexend", serif;
  border-radius: 10px;
  border-color: #78290f;
}

.manage-page-header-actions button {
  font-size: 16px;
  font-weight: 600;
  font-family: "Lexend", serif;
  line-height: 24px;
  padding: 8px 10px;
  border: none;
  border-radius: 10px;
  color: white;
  background-color: #187cd9;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

.manage-page-header-actions button:hover {
  background-color: #0e497f;
}

.manage-table {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 20px;
  margin-bottom: 15px;
  background-color: #fff;
  border-radius: 10px;
  overflow-y: auto;
}

.manage-table table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
}

.manage-table table thead tr {
  text-align: left;
  border-bottom: 10px solid #dddddd;
}

.manage-table table thead tr th {
  font-size: 20px;
  font-family: "Lexend", serif;
  color: #580c1f;
}

.manage-table table th,
.manage-table table td {
  padding: 10px;
  text-align: left;
  vertical-align: top;
  font-family: "Manrope", serif;
}

.manage-table table.revista tbody tr td.actions {
  justify-content: flex-end;
  margin-right: 30px;
}

.manage-table table.revista thead tr th.act {
  text-align: right;
  padding-right: 40px;
}

.manage-table table tbody tr {
  border-bottom: 2px solid #9caea9;
}

.manage-table table tbody tr td {
  font-size: 18px;
}

.manage-table table tbody tr td.actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.manage-table table tbody tr td.actions button {
  border: none;
  outline: none;
  background-color: transparent;
}

.manage-table table tbody tr td button i {
  font-size: 20px;
  cursor: pointer;
}

.manage-table table tbody tr td button i.fa-pen-to-square {
  color: #023e8a;
}

.manage-table table tbody tr td button i.fa-trash {
  color: red;
}

.pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.pagination-control-btn {
  background-color: transparent;
  border: none;
  color: #65676d;
  font-size: 16px;
  font-weight: 500;
  font-family: "Manrope", serif;
  text-align: right;
  line-height: 24px;
  cursor: pointer;
}

.pagination-control-btn:hover {
  border-top: 2px solid #f67b3d;
  color: #002445;
}

.pagination-control-btn.disabled:hover {
  border-top: none;
  color: #002445;
}

.pagination-control-btn.disabled {
  cursor: not-allowed;
}

.pagination-current-page {
  font-size: 16px;
  font-weight: 500;
  font-family: "Manrope", serif;
  color: #5fa8d3;
  line-height: 24px;
}

/* Edit lajme page */
.edit-page {
  width: 100%;
  margin: auto;
  padding: 50px 30px;
  background-color: #f9f9f9;
  border-radius: 10px;
}

.edit-page p {
  font-size: 32px;
  font-weight: 600;
  font-family: "Lexend", serif;
  font-style: italic;
  color: #0e497f;
  text-align: left;
  margin-bottom: 15px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group.gallery {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.form-group.cover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  font-family: "Lexend", serif;
}

input,
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

textarea {
  width: "100%";
  height: fit-content;
  resize: "none";
  overflow: "hidden";
  border: "none";
  font-size: "16px";
  line-height: "1.6";
  background: "transparent";
  outline: "none";
}

.images-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #9caea9;
}

.image-wrapper {
  position: relative;
  width: 120px;
  height: 120px;
}

.image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.image-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.delete-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(255, 0, 0, 0.8);
  color: white;
  border: none;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  font-size: 14px;
  font-weight: 600;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.image-wrapper:hover .delete-button {
  display: flex;
}

.delete-button:hover {
  background-color: rgba(255, 0, 0, 1);
  transform: scale(1.1);
}

.edit-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.edit-actions button {
  border: none;
  padding: 5px 8px;
  border-radius: 10px;
  color: white;
  font-size: 18px;
  font-weight: 600;
  font-family: "Manrope", serif;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

.edit-actions button.save {
  background-color: #7ae582;
}

.edit-actions button.save:hover {
  background-color: #66c16c;
}

.edit-actions button.cancel {
  background-color: #ff5a5f;
}

.edit-actions button.cancel:hover {
  background-color: #d64b50;
}

.replace {
  border: none;
  background-color: rgb(13, 116, 120);
  color: white;
  font-size: 16px;
  font-weight: 500;
  border-radius: 10px;
  padding: 5px 8px;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  color: #fff;
  font-size: 14px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.3s infinite linear;
  transform: translateZ(0);
}

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
      0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
      0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

.error {
  display: block;
  width: 100%;
  text-align: left;
  color: red;
}

@media (width<=1410px) {
  .manage-page-header p {
    font-size: 32px;
  }
}

@media (width<=1300px) {
  .layout {
    gap: 15px;
  }
}

@media (width<=880px) {
  .layout {
    padding: 0;
  }

  .manage,
  .manage-content {
    border-radius: 0;
  }

  .pagination {
    margin-bottom: 30px;
  }
}

@media (width<= 830px) {
  .manage-table table {
    border: 0;
  }

  .manage-table table thead {
    display: none;
  }

  .manage-table table tbody tr {
    display: block;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px;
    background-color: #fff;
  }

  .manage-table table tbody tr td {
    display: flex;
    justify-content: space-between;
    padding: 10px 5px;
    border-bottom: 1px solid #ddd;
  }

  .manage-table table tbody tr td:last-child {
    border-bottom: 0;
  }

  .manage-table table tbody tr td::before {
    content: attr(data-label);
    font-weight: bold;
    color: #780000;
    flex: 1;
    padding-right: 10px;
  }

  .manage-table table tbody tr td.actions {
    justify-content: flex-start;
    margin-top: 10px;
  }

  .manage-table table tbody tr td.actions button i {
    margin-right: 10px;
  }
}

@media (width<=665px) {
  .manage-page-header p {
    font-size: 26px;
  }

  .manage-page-header-actions {
    gap: 20px;
  }
}

@media (width<=605px) {
  .manage-page-header-actions {
    flex-direction: column;
  }
}

@media (width<=465px) {
  .manage-page-header-actions input {
    width: 200px;
  }
}

@media (width<=430px) {
  .manage-page-header p {
    font-size: 22px;
  }
}

@media (0px <= height <= 660px) {
  .manage p {
    font-size: 42px;
  }
}

@media (661px <= height <= 740px) {
  .manage p {
    font-size: 48px;
  }
}
