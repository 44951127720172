.statistics-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 130px;
  box-sizing: border-box;
}

.statistics-title {
  width: 100%;
  font-size: 48px;
  font-weight: 600;
  font-family: "Work Sans", serif;
  text-align: center;
  padding-bottom: 30px;
  color: #003249;
}

.statistics-data {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
}

.salla-data-widgets,
.salla-online-widgets,
.katalogu-widgets,
.tema-widgets,
.salla-18-widgets,
.salla-19-widgets,
.salla-20-widgets,
.salla-24-widgets,
.salla-online24-widgets,
.salla-25-widgets,
.salla-online25-widgets {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  border-radius: 10px;
  padding: 30px;
}

.salla-data-widgets {
  border: 2px dashed #5fa8d3;
}

.salla-online-widgets {
  border: 2px dashed #f95738;
}

.katalogu-widgets {
  border: 2px dashed #fb6f92;
}

.tema-widgets {
  border: 2px dashed #d8aaf9;
}

.salla-18-widgets {
  border: 2px dashed #2ec4b6;
}

.salla-19-widgets {
  border: 2px dashed #a98467;
}

.salla-20-widgets {
  border: 2px dashed #a53860;
}

.salla-24-widgets {
  border: 2px dashed #004e89;
}

.salla-online24-widgets {
  border: 2px dashed #2ec4b6;
}

.salla-25-widgets {
  border: 2px dashed #ff70a6;
}

.salla-online25-widgets {
  border: 2px dashed #6d597a;
}

.salla-widgets,
.salla-online-widgets,
.katalogu-data-widgets,
.tema-data-widgets,
.salla-data-18-widgets,
.salla-data-19-widgets,
.salla-data-20-widgets,
.salla-data-24-widgets,
.salla-data-24-widgets,
.salla-data-25-widgets {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 80px;
}

.salla-data-title,
.salla-online-title,
.katalogu-title,
.tema-title,
.salla-18-title,
.salla-19-title,
.salla-20-title,
.salla-24-title,
.salla-online24-title,
.salla-25-title,
.salla-online25-title {
  width: 100%;
  font-size: 28px;
  font-weight: 600;
  font-family: "Lexend", serif;
  text-align: center;
}

.data-widget {
  height: 100px;
  width: 300px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 16px;
  cursor: pointer;
}

.user-widgets .data-widget {
  width: 250px;
}

.data-widget.user {
  background-color: #9ecdf4;
}

.data-widget.mikrofilm {
  background-color: #fff6cc;
}

.data-widget.dosje {
  background-color: #f9d1d9;
}

.data-widget.under30 {
  background-color: #d6eadf;
}

.data-widget.from30To45 {
  background-color: #bcd4e6;
}

.data-widget.from45To60 {
  background-color: #fae0e4;
}

.data-widget.over60 {
  background-color: #dec9e9;
}

.data-widget.studimore {
  background-color: #ffedd8;
}

.data-widget.biografike {
  background-color: #c7f9cc;
}

.data-widget.pronesore {
  background-color: #e6dfea;
}

.data-widget.katalogu-user {
  background-color: #eff7f6;
}

.data-widget.fonde {
  background-color: #ffe5ec;
}

.data-widget.katalogu-dosje {
  background-color: #d8f3dc;
}

.data-widget.kerkesa-18 {
  background-color: #fffcf2;
}
.data-widget.dosje-18 {
  background-color: #e1ecf7;
}

.data-widget.kerkesa-19 {
  background-color: #ffe5ec;
}
.data-widget.dosje-19 {
  background-color: #edf6f9;
}

.data-widget.kerkesa-20 {
  background-color: #f4f3ee;
}
.data-widget.dosje-20 {
  background-color: #dfe7fd;
}

.data-widget.kerkesa-24 {
  background-color: #fae0e4;
}
.data-widget.dosje-24 {
  background-color: #caf0f8;
}

.data-widget.kerkesa-online24 {
  background-color: #e0fbfc;
}

.data-widget.dosje-online24 {
  background-color: #d8f3dc;
}

.data-widget.kerkesa-25 {
  background-color: #f6fff8;
}
.data-widget.dosje-25 {
  background-color: #fff0f3;
}

.data-widget.kerkesa-online25 {
  background-color: #f7e4de;
}

.data-widget.dosje-online25 {
  background-color: #caf0f8;
}

.widget-info {
  font-size: 26px;
  font-weight: 500;
  font-family: "Manrope", serif;
}

.widget-title {
  font-size: 24px;
  font-weight: 500;
  font-family: "Manrope", serif;
}

@media (width<=1300px) {
  .statistics-page {
    padding: 30px 70px;
  }
}

@media (width<=1105px) {
  .salla-widgets,
  .salla-online-widgets,
  .katalogu-data-widgets,
  .tema-data-widgets,
  .salla-data-18-widgets,
  .salla-data-19-widgets,
  .salla-data-20-widgets,
  .salla-data-24-widgets,
  .salla-data-24-widgets,
  .salla-data-25-widgets {
    gap: 50px;
  }
}

@media (width<=1050px) {
  .statistics-page {
    padding: 30px 30px;
  }
}

@media (width<=970px) {
  .data-widget {
    height: 130px;
    justify-content: center;
  }
}

@media (width<=880px) {
  .salla-widgets,
  .salla-online-widgets,
  .katalogu-data-widgets,
  .tema-data-widgets,
  .salla-data-18-widgets,
  .salla-data-19-widgets,
  .salla-data-20-widgets,
  .salla-data-24-widgets,
  .salla-data-24-widgets,
  .salla-data-25-widgets {
    gap: 30px;
  }
}

@media (width<=590px) {
  .data-widget {
    width: 200px;
  }

  .salla-widgets,
  .katalogu-data-widgets,
  .tema-data-widgets,
  .salla-data-18-widgets,
  .salla-data-19-widgets,
  .salla-data-20-widgets,
  .salla-data-24-widgets,
  .salla-data-24-widgets,
  .salla-data-25-widgets {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
}

@media (width<=520px) {
  .salla-widgets,
  .katalogu-data-widgets,
  .tema-data-widgets,
  .salla-data-18-widgets,
  .salla-data-19-widgets,
  .salla-data-20-widgets,
  .salla-data-24-widgets,
  .salla-data-24-widgets,
  .salla-data-25-widgets {
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .data-widget {
    width: 250px;
  }
}
