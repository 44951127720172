.warning {
  padding: 30px 130px;
  box-sizing: border-box;
}

.notify-warning {
  color: #262c00;
  text-align: center;
  background-color: #f0ff9c;
  border-radius: 5px;
  width: 100%;
  padding: 30px;
  font-family: "Manrope", serif;
  font-size: 20px;
  line-height: 30px;
}

@media (width <= 1425px) {
  .warning {
    padding: 10px 50px;
  }
}
