.videos-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 auto;
  padding: 0 130px;
}

.videos-title {
  width: 1250px;
  text-align: left;
  font-family: "Work Sans", serif;
  font-size: 22px;
  margin: 20px 0 10px 30px;
}

.video-text {
  margin-top: 10px;
  font-family: "Manrope", serif;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
}

.swiper-slide img.carousel-image {
  height: 225px;
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
}

.videos-container .swiper-horizontal > .swiper-pagination-bullets,
.videos-container .swiper-pagination-bullets.swiper-pagination-horizontal,
.videos-container .swiper-pagination-custom,
.videos-container .swiper-pagination-fraction {
  position: relative;
}

.swiper-button-prev,
.swiper-button-next {
  margin-top: -50px;
}

@media (width<=1665px) {
  .video {
    width: 400px;
    box-sizing: border-box;
  }

  .video iframe {
    width: 400px;
    height: 225px;
  }
}

@media (width <= 1425px) {
  .videos-container {
    padding: 0 50px;
  }
}

@media (width<=1340px) {
  .swiper-slide img.carousel-image {
    height: 200px;
  }
}

@media (width<=1330px) {
  .video,
  .video iframe {
    width: 350px;
  }

  .videos-title {
    width: 1100px;
  }
}

@media (width <=1285px) {
  .videos-container .swiper-slide {
    width: 278px;
  }
}

@media (width <=1240px) {
  .videos-title {
    width: 1000px;
  }

  .swiper-slide img.carousel-image {
    height: 180px;
  }
}

@media (width <= 1200px) {
  .videos-container {
    padding: 0 30px;
  }
}

@media (width <=1170px) {
  .swiper-slide img.carousel-image {
    width: 85%;
  }

  .videos-container .swiper-horizontal > .swiper-pagination-bullets,
  .videos-container .swiper-pagination-bullets.swiper-pagination-horizontal,
  .videos-container .swiper-pagination-custom,
  .videos-container .swiper-pagination-fraction {
    margin-top: 15px;
  }
}

@media (width <= 1155px) {
  .videos-container {
    padding: 0 10px;
  }

  .videos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px;
  }
}

@media (width <=1100px) {
  .videos-title {
    width: 900px;
  }

  .swiper-slide img.carousel-image {
    width: 95%;
    height: 200px;
  }
}

@media (width <=1030px) {
  .swiper-slide img.carousel-image {
    width: 85%;
    height: 190px;
  }
}

@media (width<=992px) {
  .videos-title {
    width: 700px;
  }
}

@media (width<=980px) {
  .swiper-slide img.carousel-image {
    width: 70%;
    height: 200px;
  }
}

@media (width<=860px) {
  .videos-title {
    width: 650px;
  }
}

@media (width<=800px) {
  .swiper-slide img.carousel-image {
    width: 80%;
    height: 200px;
  }
}

@media (width<=725px) {
  .videos-title {
    width: 550px;
  }
}

@media (width<=650px) {
  .swiper-slide img.carousel-image {
    height: 180px;
  }
}

@media (width<=600px) {
  .videos-title {
    width: 450px;
  }

  .swiper-slide img.carousel-image {
    width: 65%;
    height: 220px;
  }
}

@media (width<=485px) {
  .videos-title {
    width: 350px;
  }
}

@media (width<=400px) {
  .video,
  .video iframe {
    width: 320px;
  }
}
