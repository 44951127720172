.services {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #525252;
  padding: 20px 0;
}

.citizen-services,
.online-services,
.online-shop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.services-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.services-icons img,
.services-icons img,
.services-icons img {
  height: 130px;
  border-radius: 10px;
  transition: all 0.4s ease-in-out;
}

.services-icons img:hover,
.services-icons img:hover,
.services-icons img:hover {
  transform: scale(1.1);
  cursor: pointer;
  transform: scale(1.1, 1.1);
}

.services-title {
  font-size: 16px;
  font-weight: 500;
  color: white;
  font-family: "Work Sans", serif;
}

.page-content-kerkesa {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 0 130px;
  margin-top: 30px;
}

.page-content-kerkesa ul {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  list-style-type: none;
}

.page-content-kerkesa ul li {
  list-style-type: disc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  position: relative;
  padding-left: 30px;
}

.page-content-kerkesa ul li:before {
  content: "☉";
  position: absolute;
  left: 0;
  font-weight: bold;
  font-size: 24px;
  color: #333333;
}

.page-content-kerkesa ul li a {
  color: black;
  font-size: 16px;
  font-family: "Manrope", serif;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-decoration: none;
}

.page-content-kerkesa ul li a:visited {
  color: black;
}

.page-content-kerkesa ul li a:hover {
  color: red;
}

.page-content-kerkesa ul li a p.kerkesa-item-title {
  font-weight: 600;
  font-family: "Lexend", serif;
}

.page-content-kerkesa ul li a p {
  font-family: "Manrope", serif;
}

.restaurim {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 250px;
}

.restaurim .restaurim-title {
  text-align: center;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 30px;
  font-family: "Lexend", serif;
}

.restaurim-content {
  color: #333;
  text-align: justify;
  font-family: "Manrope", serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.restaurim .restaurim-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  max-width: 500px;
  margin: 40px auto 120px;
}

.restaurim .restaurim-form .form-row {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.restaurim .restaurim-form .form-row .form-row-element {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.restaurim .restaurim-form .form-row .form-row-element label {
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: 700;
  display: block;
  font-family: "Manrope", serif;
}

.restaurim .restaurim-form .form-row .form-row-element input {
  color: #333;
  background-color: #fff;
  border: 1px solid #ccc;
  width: 100%;
  height: 38px;
  margin-bottom: 10px;
  padding: 8px 12px;
  font-size: 14px;
  font-family: "Manrope", serif;
  line-height: 1.42857;
  display: block;
}

.restaurim .restaurim-form .form-row .form-row-element span {
  color: #888;
  margin-top: -6px;
  font-size: 13px;
  font-family: "Manrope", serif;
}

.restaurim .notification {
  background-color: #fdffd0;
  flex: 0 auto;
  width: 100%;
  margin-top: 20px;
  margin-left: -2%;
  margin-right: -2%;
  padding: 20px;
}

.restaurim .notification .notification-title {
  color: #484b0f;
  margin-bottom: 10px;
  font-family: "Manrope", serif;
  font-weight: 600;
  font-size: 18px;
  text-align: justify;
}

.restaurim .notification .notification-content {
  color: #696d17;
  margin-top: -6px;
  font-size: 16px;
  font-family: "Manrope", serif;
  line-height: 24px;
  text-align: justify;
}

.restaurim .button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.restaurim .button .send {
  background-color: red;
  color: #fff;
  font-family: "Manrope", serif;
  transition: all 0.12s;
  cursor: pointer;
  border: none;
  padding: 9px 15px;
  text-decoration: none;
}

@media (width<=1550px) {
  .services-icons img,
  .services-icons img,
  .services-icons img {
    height: 110px;
  }
}

@media (width<=1450px) {
  .page-content-kerkesa {
    padding: 0 100px;
  }

  .restaurim {
    padding: 0 100px;
  }
}

@media (width <=1325px) {
  .services-icons img,
  .services-icons img,
  .services-icons img {
    height: 90px;
  }
}

@media (width<=1250px) {
  .page-content-kerkesa {
    padding: 0 50px;
  }

  .restaurim {
    padding: 0 50px;
  }
}

@media (width <=1091px) {
  .services-icons img,
  .services-icons img,
  .services-icons img {
    height: 100px;
  }

  .services {
    flex-direction: column;
    gap: 20px;
  }
}

@media (width<=992px) {
  .page-content-kerkesa {
    padding: 0 20px;
  }

  .restaurim {
    padding: 0 20px;
  }
}

@media (width <=500px) {
  .services-icons img,
  .services-icons img,
  .services-icons img {
    height: 80px;
  }
}
