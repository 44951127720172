.revista-items-container,
.botime-items-container {
  margin: 40px 0;
  padding: 0 170px;
  box-sizing: border-box;
}

.revista-items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.revista-items > :nth-last-child(1),
.revista-items > :nth-last-child(2),
.revista-items > :nth-last-child(3) {
  justify-self: center;
}

.revista-item .image {
  vertical-align: middle;
  max-width: 100%;
  display: inline-block;
  padding: 0 10px;
  margin-bottom: 10px;
}

.botime-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-items: center;
}

.botime-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 40px;
  padding-left: 15px;
  padding-right: 15px;
}

.botime-item-image {
  width: 100%;
}

.botime-item-image img {
  vertical-align: middle;
  max-width: 100%;
  display: inline-block;
}

.botime-item-title {
  font-family: "Lexend", serif;
  font-size: 22px;
  line-height: 28px;
  text-align: left;
  margin-top: 20px;
}

.botime-item-author {
  color: #555;
  margin-top: 10px;
  font-size: 16px;
  text-align: left;
  font-family: "Manrope", serif;
}

@media (width<=1450px) {
  .revista-items-container,
  .botime-items-container {
    padding: 0 100px;
  }
}

@media (width<=1250px) {
  .revista-items-container,
  .botime-items-container {
    padding: 0 50px;
  }
}

@media (width<=1080px) {
  .revista-items {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (width<=992px) {
  .revista-items-container,
  .botime-items-container {
    padding: 0 20px;
  }
}

@media (width<=900px) {
  .botime-items {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (width<=730px) {
  .revista-items {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width<=620px) {
  .botime-items {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (width<=520px) {
  .revista-items {
    grid-template-columns: repeat(1, 1fr);
  }
}
