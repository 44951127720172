.all-posts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 130px;
}
.search-filter {
  max-width: 350px;
  padding: 0 0 30px 0;
  margin: 0 auto;
}

.input-grey-rounded {
  font-size: 16px;
  line-height: 1.5;
  background: #e9eef0;
  border: 1px solid #d5d9db;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'><path fill='%238C92A0' d='M11.44 10.73l4.41 4.42a.5.5 0 1 1-.7.7l-4.42-4.41a6.5 6.5 0 1 1 .7-.7v-.01zM6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z'></path></svg>");
  background-repeat: no-repeat;
  background-position: 15px 10px;
  background-size: 20px 20px;
  border-radius: 100px;
  width: 350px;
  padding: 0.5em 1em 0.5em 2.5em;
}

.input-grey-rounded::placeholder {
  color: #838d99;
}

.input-grey-rounded:focus {
  outline: none;
  background-color: #fff;
  border: 1px solid #84a2fa;
}

.all-posts-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 50px;
}

.all-posts-title {
  width: 100%;
  font-size: 32px;
  font-weight: 600;
  font-family: "Work Sans", serif;
  text-align: center;
  padding: 50px;
  cursor: pointer;
  color: #9b2226;
}

.post-card {
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px;
  box-sizing: border-box;
  transition: 0.4s all ease-in-out;
}

.post-card:hover {
  border: 2px solid #8eecf5;
}

.post-title {
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  font-family: "Lexend", serif;
  text-align: left;
  cursor: pointer;
  color: #370617;
}

.post-title:hover {
  color: #891840;
}

.post-img {
  height: 150px;
  width: auto;
  cursor: pointer;
}

.post-createdOn {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  font-family: "Manrope", serif;
  color: #ced2dc;
  cursor: pointer;
  text-align: right;
}

.load-more {
  border: 1px solid #03045e;
  color: #03045e;
  background-color: #caf0f8;
  font-size: 16px;
  padding: 6px 8px;
  margin-top: 30px;
  border-radius: 10px;
}

.carousel-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 auto;
}

.carousel-title {
  width: 1250px;
  text-align: left;
  font-family: "Work Sans", serif;
  font-size: 22px;
  margin: 20px 0 10px 30px;
}

.swipper-container {
  display: flex;
  justify-content: center;
}

.swiper {
  width: 100%;
  max-width: 1350px;
}

.custom-swiper-button-prev,
.custom-swiper-button-next {
  font-size: 24px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
  color: #333;
}

.custom-swiper-button-prev i,
.custom-swiper-button-next i {
  font-size: 42px;
}

.custom-swiper-button-prev {
  left: 100px;
}

.custom-swiper-button-next {
  right: 100px;
}

.card-item {
  padding: 5px;
  text-align: center;
  cursor: pointer;
}

.card-item:hover .card-title {
  color: red;
}

.card-title {
  font-family: "Manrope", serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3em;
  text-align: left;
  margin-top: 10px;
  transition: all 0.4s ease-in-out;
}

.card-title:hover {
  color: red;
  cursor: pointer;
}

.card-image-wrapper {
  width: 100%;
  height: 300px;
}

.card-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.card-date {
  color: #555;
  margin-top: 5px;
  font-family: "Manrope", serif;
  font-size: 14px;
  text-align: left;
}

.swiper-pagination {
  bottom: 10px;
}

.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #d3d3d3;
  opacity: 1;
  margin: 0 5px;
}

.carousel-container.posts .swiper-horizontal > .swiper-pagination-bullets,
.carousel-container.posts
  .swiper-pagination-bullets.swiper-pagination-horizontal,
.carousel-container.posts .swiper-pagination-custom,
.carousel-container.posts .swiper-pagination-fraction {
  margin-top: 30px;
}

.swiper-pagination-bullet-active {
  background-color: #555;
}

.page-format {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 25px 130px 10px;
}

.page-format-left {
  width: 67%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.page-left-date {
  color: #777;
  font-size: 12px;
  font-family: "Lexend", serif;
}

.page-left-title {
  text-align: left;
  margin-top: 0;
  font-family: "Manrope", serif;
  font-size: 38px;
  line-height: 44px;
}

.page-left-description {
  margin-top: 20px;
  margin-bottom: 50px;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
}

.page-left-description pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  width: auto;
  font-size: 18px;
  font-family: "Manrope", serif;
  line-height: 25px;
  text-align: left;
}

.page-left-gallery {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;
  overflow: hidden;
}

.page-left-gallery .image-wrapper {
  height: 160px;
  width: 160px;
  object-fit: cover;
}

.page-left-gallery .image {
  height: 160px;
  width: 160px;
  object-fit: cover;
  cursor: pointer;
  margin-bottom: 30px;
}

.page-format-right {
  width: 33%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.page-right-title {
  width: 250px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 700;
  font-family: "Work Sans", serif;
  line-height: 30px;
  text-align: left;
}

.page-right-posts {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
}

.page-right-post {
  max-width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.page-right-post-title {
  font-family: "Lexend", serif;
  font-size: 18px;
  text-align: left;
  transition: all 0.4s ease-in-out;
}

.page-right-post-title:hover {
  color: red;
}

.page-right-post-image img {
  vertical-align: middle;
  max-width: 100%;
  display: inline-block;
  cursor: pointer;
}

.modal-post {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content-post {
  max-width: 80%;
  max-height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content-post img {
  max-width: 100%;
  max-height: calc(100vh - 60px);
  object-fit: contain;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 2rem;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
}

.modal-prev-post,
.modal-next-post {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
}

.modal-prev-post {
  left: 10px;
}

.modal-next-post {
  right: 10px;
}

.modal-prev:hover,
.modal-next:hover,
.modal-close:hover {
  color: #ccc;
}

@media (width<=1620px) {
  .carousel-container.posts .swiper {
    max-width: 1250px;
  }

  .card-image-wrapper,
  .card-image {
    height: 250px;
  }
}

@media (width<=1560px) {
  .post-card {
    width: 350px;
  }
}

@media (width<=1520px) {
  .custom-swiper-button-next {
    right: 50px;
  }

  .custom-swiper-button-prev {
    left: 50px;
  }
}

@media (width<=1400px) {
  .custom-swiper-button-next {
    right: 20px;
  }

  .custom-swiper-button-prev {
    left: 20px;
  }

  .all-posts {
    padding: 0 100px;
  }

  .post-card {
    width: 300px;
  }
}

@media (width<=1340px) {
  .carousel-title {
    width: 1100px;
  }

  .swiper-slide {
    max-width: 90%;
    margin: 0;
  }

  .card-item {
    padding: 0;
  }

  .swipper-container {
    width: 100%;
  }

  .carousel-container.posts .swiper {
    width: 90%;
  }

  .card-image-wrapper,
  .card-image {
    height: 220px;
  }

  .page-format {
    padding: 25px 100px 10px;
  }
}

@media (width <=1240px) {
  .carousel-title {
    width: 1000px;
  }

  .carousel-container.posts .swiper {
    width: 85%;
  }

  .card-image-wrapper,
  .card-image {
    height: 200px;
  }

  .all-posts {
    padding: 0 50px;
  }
}

@media (width<=1150px) {
  .page-format {
    padding: 25px 50px 10px;
  }
}

@media (width <=1100px) {
  .carousel-title {
    width: 900px;
  }

  .carousel-container.posts .swiper {
    width: 80%;
  }

  .post-card {
    width: 350px;
  }
}

@media (width<=992px) {
  .card-image,
  .card-image-wrapper {
    height: 220px;
  }

  .carousel-title {
    width: 750px;
  }

  .swiper-wrapper {
    margin-bottom: 30px;
  }

  .page-right-post {
    max-width: 230px;
  }

  .page-right-title {
    width: 230px;
  }

  .page-left-gallery .image-wrapper,
  .page-left-gallery .image {
    height: 150px;
    width: 150px;
  }
}

@media (width<=925px) {
  .page-format {
    padding: 25px 20px 10px;

    flex-direction: column;
    gap: 30px;
  }
  .page-format-left {
    width: 100%;
    padding-bottom: 20px;
    border-bottom: 1px solid #555;
  }

  .page-left-title {
    font-size: 28px;
  }

  .page-left-gallery .image-wrapper,
  .page-left-gallery .image {
    height: 180px;
    width: 180px;
  }

  .page-format-right {
    width: 100%;
  }

  .page-right-title {
    width: 100%;
  }

  .page-right-posts {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .page-right-post {
    max-width: 250px;
  }
}

@media (width<=860px) {
  .carousel-title {
    width: 600px;
  }

  .all-posts {
    padding: 0 20px;
  }
}

@media (width<=795px) {
  .all-posts-container {
    gap: 30px;
  }

  .post-card {
    width: 300px;
  }
}

@media (width>=730px) {
  .card-title,
  .card-date {
    padding-left: 10px;
  }
}

@media (width<=730px) {
  .carousel-container.posts .swiper {
    width: 65%;
  }

  .swiper-slide {
    max-width: 100%;
  }

  .card-image-wrapper,
  .card-image {
    height: 250px;
    width: 100%;
  }

  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    display: none;
  }
}

@media (width<=725px) {
  .carousel-title {
    width: 500px;
  }

  .card-image-wrapper,
  .card-image {
    height: 220px;
    width: 100%;
  }
}

@media (width<=795px) {
  .post-card {
    width: 400px;
  }
}

@media (width<=600px) {
  .carousel-title {
    width: 400px;
  }

  .card-image-wrapper,
  .card-image {
    height: 200px;
    width: 100%;
  }
}

@media (width<=540px) {
  .carousel-container.posts .swiper {
    width: 75%;
  }

  .custom-swiper-button-next i,
  .custom-swiper-button-prev i {
    font-size: 32px;
  }
}

@media (width<=485px) {
  .carousel-title {
    width: 300px;
  }

  .card-image-wrapper,
  .card-image {
    height: 180px;
    width: 100%;
  }
}
