.sidebar-container {
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  align-self: flex-start;
  height: calc(100vh - 135px);
  overflow-y: hidden;
  overflow-x: visible;
  flex-shrink: 0;
}

.sidebar-container.collapsed {
  position: relative;
}

.sidebar-container.expanded {
  width: 320px;
  box-sizing: border-box;
}

.sidebar {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-sizing: border-box;
  padding: 15px;
  border-radius: 10px;
  transition: width 0.3s, left 0.3s;
  z-index: 1000;
}

.sidebar.expanded {
  width: 320px;
}

.sidebar.collapsed {
  height: 100%;
  position: absolute;
  width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.head.expanded {
  display: flex;
  gap: 20px;
}

.head.collapsed .logo.collapsed {
  display: none;
}

.logo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo.expanded img {
  width: 70px;
  height: 70px;
}

.logo.collapsed img {
  width: 50px;
  height: 50px;
}

.nav {
  flex: 1;
}

.nav .title,
.menu .title {
  font-size: 14px;
  font-weight: 500;
  color: #757575;
  text-transform: uppercase;
  margin: 0;
  text-align: left;
  padding: 0 10px;
}

.nav.collapsed {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.menu.collapsed {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menu.collapsed .nav .title,
.menu .title {
  display: none;
}

.menu.collapsed ul {
  padding: 0;
}

.menu ul {
  padding-left: 15px;
  margin: 0;
  margin-bottom: 15px;
}

.menu.collapsed ul li {
  padding: 10px 8px;
}

.menu ul li {
  position: relative;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  font-size: 18px;
  font-weight: 500;
  font-family: "Lexend", serif;
  text-transform: uppercase;
  color: #65676d;
  text-decoration: none;
  padding: 6px 8px;
  transition: all 0.3s;
  cursor: pointer;
}

.menu.collapsed ul li {
  font-size: 28px;
}

.menu ul li:hover {
  background-color: #f3f3f3;
  color: #f67b3d;
}

.menu ul li:hover .icon {
  color: #f67b3d;
}

.menu ul li.active {
  border-radius: 10px;
  color: #f67b3d;
  border-left: 5px solid #f67b3d;
  background-color: #f3f3f3;
}

.menu ul li.active .icon {
  color: #f67b3d;
}

.arrow {
  margin-left: auto;
  font-size: 24px;
}

.menu ul li .text {
  opacity: 1;
  transition: opacity 0.3s;
  text-align: left;
  font-family: "Lexend", serif;
}

.menu ul li span {
  font-family: "Lexend", serif;
}

.menu ul.sub-menu {
  list-style: none;
  padding-left: 20px;
  margin-top: 10px;
  margin-bottom: 0;
}

.menu ul.sub-menu li a {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: 500;
  color: #65676d;
  padding: 8px 8px;
  transition: all 0.3s;
}

.sidebar.collapsed .text {
  opacity: 0;
}

.sidebar.collapsed .sub-menu {
  display: none;
}

.menu-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 10px;
  width: 30px;
  height: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  background-color: white;
  z-index: 99999;
}

.menu-btn.collapsed {
  position: absolute;
  left: 65px;
  top: 30px;
  transform: translateY(-50%);
}

.menu-btn.expanded {
  position: absolute;
  right: -15px;
}

@media (max-width: 1500px) {
  .sidebar.expanded {
    width: 320px;
  }

  .sidebar-container.collapsed {
    width: 100px;
  }

  .sidebar.collapsed {
    width: 80px;
  }
}

@media (min-width: 1500px) {
  .sidebar {
    width: 320px;
  }
}

@media (max-width: 1500px) {
  .sidebar.expanded {
    position: absolute;
    width: 300px;
  }

  .sidebar {
    position: relative;
    width: 320px;
    left: auto;
    z-index: auto;
  }

  .sidebar.collapsed {
    position: relative;
    width: 80px;
    left: auto;
    z-index: auto;
  }

  .menu-btn.expanded {
    right: -15px;
    z-index: auto;
  }
}

@media (width <= 1300px) {
  .sidebar-container.expanded {
    width: 300px;
  }

  .sidebar.expanded {
    width: 280px;
  }

  .menu ul li a {
    font-size: 20px;
  }
}

@media (width <= 992px) {
  .sidebar-container.expanded {
    width: 270px;
  }

  .sidebar.expanded {
    width: 250px;
  }

  .sidebar {
    padding: 5px 15px;
  }

  .menu ul {
    padding-left: 10px;
  }
}
@media (width <= 880px) {
  .menu-btn.collapsed {
    display: none;
  }

  .sidebar-container {
    display: none;
  }
}

@media (width <= 430px) {
  .sidebar.collapsed {
    padding: 24px 15px;
    width: max-content;
  }

  .logo.collapsed img {
    width: 40px;
    height: 40px;
  }
}

@media (0px <= height <= 660px) {
  .menu.collapsed ul li {
    padding: 5px 8px;
    font-size: 24px;
  }

  .sidebar.expanded {
    padding: 10px;
  }
}

@media (661px <= height <= 740px) {
  .menu.collapsed ul li {
    padding: 8px 8px;
    font-size: 24px;
  }

  .sidebar.expanded {
    padding: 15px;
  }
}

@media (741px <= height <= 800px) {
  .menu.collapsed ul li {
    padding: 8px 8px;
    font-size: 28px;
  }

  .menu ul li {
    padding: 8px 8px;
    font-size: 20px;
  }
}

@media (801px <= height <= 880px) {
  .menu.collapsed ul li {
    padding: 10px 8px;
  }

  .menu ul li {
    font-size: 22px;
  }
}

@media (881px <= height <= 960px) {
  .menu.collapsed ul li {
    padding: 12px 8px;
    font-size: 32px;
  }

  .menu ul li {
    padding: 8px 8px;
    font-size: 22px;
  }
}
