.dashboard {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
  overflow: hidden;
}

.dashboard-widgets {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  gap: 30px;
  box-sizing: border-box;
}

.widget {
  height: 90px;
  width: 300px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  padding: 16px;
  cursor: pointer;
}

.dashboard-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.widget-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.widget-icon {
  height: 100%;
  padding: 0 20px;
}

.widget-icon i {
  font-size: 24px;
}

.widget-icon.postime {
  background-color: #bbdefb;
}

.widget-icon.postime i {
  color: #1565c0;
}

.widget-icon.videos {
  background-color: #fff6cc;
}

.widget-icon.videos i {
  color: #ffd819;
}

.widget-icon.ekspozita {
  background-color: #fff0f3;
}

.widget-icon.ekspozita i {
  color: #ff4d6d;
}

.widget-icon.objekte {
  background-color: #eee2df;
}

.widget-icon.objekte i {
  color: #c97c5d;
}

.widget-icon.revista {
  background-color: #cfdffc;
}

.widget-icon.revista i {
  color: #788bff;
}

.widget-icon.botime {
  background-color: #c4fff9;
}

.widget-icon.botime i {
  color: #07beb8;
}

.dashboard-info span {
  font-size: 22px;
  font-weight: 600;
}

.widget-title {
  font-size: 20px;
  font-weight: 500;
}

.dashboard-charts {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
}

.recharts-wrapper {
  background-color: white;
  border-radius: 10px;
}

.donut-chart {
  width: 35%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.container .slice {
  background-color: white;
  border-radius: 10px;
}

@media (width>=1570px) {
  .widget {
    width: 355px;
  }

  .dashboard-info span {
    font-size: 26px;
  }

  .widget-title {
    font-size: 24px;
  }
}

@media (width<=1450px) {
  .donut-chart {
    width: 40%;
  }
}

@media (width<=1380px) {
  .widget {
    width: 270px;
  }
}

@media (width<=1260px) {
  .dashboard-widgets {
    gap: 24px;
  }
}

@media (width<=1240px) {
  .widget {
    width: 240px;
  }

  .dashboard-info {
    gap: 0;
  }

  .donut-chart {
    width: 45%;
  }
}

@media (width<=1140px) {
  .widget {
    width: 200px;
  }

  .donut-chart {
    width: 50%;
  }
}

@media (width<=1060px) {
  .dashboard-charts .donut-chart {
    display: none;
  }
}

@media (width<=1025px) {
  .dashboard-widgets {
    gap: 16px;
  }

  .widget {
    width: 180px;
  }
}

@media (width<=920px) {
  .widget-icon {
    display: none;
  }

  .dashboard-info {
    width: 100%;
  }

  .widget {
    width: 150px;
  }
}

@media (width<=880px) {
  .dashboard-widgets {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 30px;
    gap: 30px;
    box-sizing: border-box;
  }

  .widget-icon {
    display: flex;
  }

  .widget {
    width: 220px;
  }

  .recharts-responsive-container {
    padding: 30px;
    box-sizing: border-box;
  }
}

@media (width<=720px) {
  .widget {
    width: 250px;
  }
}

@media (width<=600px) {
  .widget {
    width: 220px;
  }
}

@media (width<=530px) {
  .widget {
    width: 180px;
  }

  .recharts-layer .recharts-cartesian-axis-tick {
    max-width: 100px;
  }
}

@media (width<=450px) {
  .dashboard-widgets {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 16px;
    gap: 16px;
  }

  .widget {
    width: 170px;
  }

  .widget-title {
    font-size: 18px;
  }
}
