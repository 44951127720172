* {
  padding: 0;
  margin: 0;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 130px;
  box-sizing: border-box;
  border-top: 4px solid #fa8b28;
  background-color: #fff;
  font-family: "Manrope", serif;
  box-shadow: 0 1px 15px -3px #0000001a;
  z-index: 999;
  position: sticky;
  top: 0;
}

.navbar-logo {
  width: max-content;
}

.navbar-logo img {
  height: 70px;
}

.navbar {
  margin: 0;
  padding: 0;
}

.navbar-nav {
  display: flex;
  justify-content: space-around;
  list-style: none;
  padding: 0;
  margin-top: 8px;
}

.nav-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  color: #222222;
  cursor: pointer;
  font-weight: 600;
  text-align: center;
  padding: 15px 10px 0 10px;
  font-family: "Work Sans", serif;
  font-size: 18px;
  text-decoration: none;
}

.nav-item:hover {
  background-color: #333333;
  color: white;
}

.nav-item span.subtitle {
  font-weight: 400;
  font-size: 12px;
  word-break: keep-all;
}

.nav-item:hover .dropdown-menu {
  display: block;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #333333;
  z-index: 1;
}

.dropdown-item {
  box-sizing: border-box;
  color: #fff;
  padding: 10px 20px;
  white-space: nowrap;
  text-align: left;
  text-decoration: none;
}

.dropdown-item a {
  text-decoration: none;
  color: inherit;
  font-family: "Work Sans", serif;
}

.dropdown-item a:visited {
  color: inherit;
}

.dropdown-item:hover {
  background-color: #ff6600;
}

.burger-button {
  display: none;
}

@media (width <= 1425px) {
  .header {
    padding: 10px 50px;
  }
}

@media (width <= 1255px) {
  .navbar-logo img {
    height: 50px;
  }
}

@media (width <= 1085px) {
  .header {
    padding: 10px 10px;
  }

  .navbar-logo img {
    height: 40px;
  }
}

@media (width <= 992px) {
  .burger-button {
    display: block;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
    color: #333;
    z-index: 1001;
    float: right;
    -webkit-tap-highlight-color: #0000;
    -webkit-user-select: none;
    user-select: none;
    padding: 10px 18px;
    position: relative;
    margin-right: 190px;
  }

  .burger-button.open {
    color: #fff;
    background-color: #c8c8c8;
  }

  .navbar {
    position: fixed;
    top: -100%;
    right: 0;
    height: fit-content;
    width: auto;
    box-shadow: -1px 0 10px rgba(0, 0, 0, 0.1);
    transition: top 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    z-index: 1000;
    text-align: center;
    background: #c8c8c8;
    min-width: 200px;
  }

  .navbar.open {
    top: 76.8px;
    padding-top: 0;
    width: 275px;
  }

  .navbar-nav {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0;
  }

  .nav-item {
    width: 100%;
    text-align: left;
    padding: 10px 0;
  }

  .nav-item:first-child {
    padding-top: 25px;
  }

  .dropdown-menu {
    position: static;
  }
}

@media (width <= 500px) {
  .burger-button {
    margin-right: 0;
  }
}

@media (height<=500px) {
  .navbar {
    top: -110%;
  }
}
