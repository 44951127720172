* {
  margin: 0;
  padding: 0;
}

.manage-navbar {
  background: #a2d2ff;
  min-height: 75px;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 9999;
  padding: 10px 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.manage-navbar .logo-text {
  left: 140px;
  top: 15px;
  color: #002445;
  font-size: 28px;
  font-weight: 600;
  font-family: "Lexend", serif;
  cursor: pointer;
  margin-right: 30px;
}

.manage-navbar-icons {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.manage-navbar-icons .navbar-tab a {
  text-transform: uppercase;
  background: #fff;
  border-radius: 3px;
  padding: 6px 12px;
  color: #008196;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
  z-index: 12;
  position: relative;
  float: right;
  border: 2px solid #fff;
}

.manage-navbar-icons .navbar-tab.fototeka a {
  text-transform: uppercase;
  background: #07c25f;
  border-radius: 3px;
  padding: 6px 12px;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
  z-index: 12;
  position: relative;
  float: right;
  border: 2px solid #fff;
}

.manage-navbar-icons .navbar-tab.katalogu a {
  text-transform: uppercase;
  background: #f3a800;
  border-radius: 3px;
  padding: 6px 12px;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
  z-index: 12;
  position: relative;
  float: right;
  border: 2px solid #fff;
}

.manage-navbar-icons .navbar-tab a:hover,
.manage-navbar-icons .navbar-tab a:focus,
.manage-navbar-icons .navbar-tab a:visited {
  outline: 0 !important;
  text-decoration: none;
}

.navbar-menu {
  display: none;
}

.burger-menu i {
  font-size: 22px;
  color: #002445;
}

.navbar-menu .menu-tabs.collapsed {
  display: none;
}

.navbar-menu .menu-tabs.expanded {
  position: absolute;
  top: 69.58px;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 300px;
  height: calc(100vh-69.58px);
  background-color: #b0d6fa;
  padding: 20px 0;
  transition: 0.4s all ease-in-out;
}

.menu-tab {
  width: 100%;
  display: flex;
  justify-content: flex-star;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  font-size: 22px;
  font-weight: 600;
  text-align: left;
  color: #002445;
  cursor: pointer;
  transition: 0.4s all ease-in-out;
}

.menu-tab i {
  font-size: 22px;
  color: #002445;
}

.menu-tab:hover {
  background-color: #002445;
  color: white;
}

.menu-tab:hover i {
  color: white;
  background-color: #002445;
}

.menu-tab.active {
  color: white;
}

.user-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: relative;
}

.user-profile button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  background-color: transparent;
  font-size: 18px;
  font-weight: 500;
  font-family: "Lexend", serif;
}

.user-profile img {
  height: 50px;
  margin-right: 50px;
  cursor: pointer;
}

.profile-menu {
  position: absolute;
  background-color: white;
  top: 80px;
  right: 50px;
  width: 170px;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transform: translateY(-10px);
  transition: 300ms;
  z-index: 99999;
}

.profile-menu::before {
  content: "";
  position: absolute;
  top: -10px;
  right: 14px;
  width: 20px;
  height: 20px;
  background: #fff;
  transform: rotate(45deg);
  z-index: -1;
}

.profile-menu ul {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 10;
  background: #fff;
}

.profile-menu ul li {
  list-style: none;
  font-size: 16px;
  font-weight: 500;
  font-family: "Manrope", serif;
  padding: 10px 20px;
  cursor: pointer;
}

.profile-menu ul li:hover {
  background: #bde0fe;
}

@media (width<=1030px) {
  .manage-navbar .logo-text {
    font-size: 24dpx;
  }
}

@media (width<=880px) {
  .manage-navbar-icons {
    display: none;
  }

  .navbar-menu {
    display: block;
  }

  .user-profile {
    display: none;
  }
}
