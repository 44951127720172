.page-content-kontakte {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 130px;
  margin-top: 30px;
}

.left-content,
.right-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 40px;
}

.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.contact-title,
.contact-info p {
  color: #333;
  text-align: justify;
  font-family: "Lexend", serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.contact-title {
  font-weight: 700;
  margin-bottom: 10px;
}

.contact-info p span {
  color: red;
  font-family: "Lexend", serif;
}

@media (width<=1450px) {
  .page-content-kontakte {
    padding: 0 100px;
  }
}

@media (width<=1250px) {
  .page-content-kontakte {
    padding: 0 50px;
  }
}

@media (width<=1100px) {
  .page-content-kontakte {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
  }
}

@media (width<=992px) {
  .page-content-kontakte {
    padding: 0 20px;
  }
}

@media (width<=540px) {
  .contact-info iframe {
    width: 400px;
  }
}

@media (width<=440px) {
  .contact-info iframe {
    width: 350px;
  }
}
