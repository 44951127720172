.arkiva-icons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 50px;
  margin-top: 50px;
  box-sizing: border-box;
  padding: 0 130px;
}

.arkiva-icons-row1,
.arkiva-icons-row2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.arkiva-item,
.service-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 15px 25px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  border: 1px solid transparent;
  box-sizing: border-box;
}

.arkiva-item:hover,
.service-item:hover {
  transition: all 0.4s ease-in-out;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}

.arkiva-item img,
.service-item img {
  width: 150px;
  height: 150px;
  padding: 0 10px;
}

.arkiva-item p.arkiva-title,
.service-item p.service-title {
  width: 100%;
  color: #000;
  text-align: center;
  margin-top: 15px;
  font-size: 20px;
  font-family: "Manrope", serif;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
}

.service-item p.service-desc {
  color: #555;
  text-align: center;
  max-width: 250px;
  margin-top: 10px;
  font-size: 13px;
  font-family: "Manrope", serif;
  line-height: 1.4em;
}

.page-content-doreshkrime {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 130px;
  margin-top: 30px;
}

.left-content-doreshkrime {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px;
  padding-left: 10px;
  padding-right: 30px;
}

.right-content-doreshkrime {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
  padding-left: 30px;
  padding-right: 10px;
}

.right-content-doreshkrime img {
  vertical-align: middle;
  max-width: 100%;
  display: inline-block;
  cursor: pointer;
}

.left-content-doreshkrime p {
  color: #333;
  text-align: justify;
  font-family: "Manrope", serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.left-content-doreshkrime p.subtitle {
  font-weight: 700;
}

.left-content-doreshkrime a {
  background-color: red;
  color: #fff;
  font-family: "Manrope", serif;
  transition: all 0.12s;
  padding: 9px 15px;
  text-decoration: none;
  cursor: pointer;
}

.left-content-doreshkrime a:visited {
  background-color: red;
  color: #fff;
}

.left-content-doreshkrime a:hover {
  background-color: #333;
}

.modal-doreshkrime {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 70px;
}

.modal-content-doreshkrime {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content-doreshkrime img {
  /* max-width: 95%;
  max-height: 95%; */
  width: 100%;
  height: 100%;
}

@media (width<=1400px) {
  .arkiva-item img,
  .service-item img {
    width: 120px;
    height: 120px;
  }
}

@media (width<=1170px) {
  .arkiva-icons {
    padding: 0 80px;
  }
}

@media (width<=1080px) {
  .arkiva-icons {
    padding: 0 50px;
  }
}

@media (width<=992px) {
  .arkiva-icons {
    padding: 0 10px;
  }
}

@media (width<=890px) {
  .arkiva-icons {
    padding: 0 10px;
  }

  .arkiva-icons-row1,
  .arkiva-icons-row2 {
    flex-wrap: wrap;
    justify-content: center;
  }
}
