.page-content-mepagese {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 30px;
  padding: 0 130px;
}

.page-content-mepagese .documents {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 10px;
}

.documents ul {
  list-style-type: none;
}

.documents ul li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
  transition: all 0.12s;
}

.documents ul li button {
  width: 100%;
  color: #333333;
  font-family: "Lexend", serif;
  font-size: 22px;
  font-weight: 500;
  text-align: left;
  line-height: 1.4;
  transition: all 0.12s;
  white-space: normal;
  background-color: transparent;
  border: none;
}

.documents ul li button.active {
  color: red;
}

.documents ul li:before {
  content: ">";
  position: absolute;
  left: 0;
  font-weight: 700;
  color: #333333;
  text-align: left;
}

.documents ul li button:hover {
  color: red;
}

.documents .contentText {
  width: 100%;
  margin-top: 20px;
  position: relative;
  color: #333;
  text-align: justify;
  font-family: "Manrope", serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  margin-left: 28px;
}

.documents .contentText p {
  margin-bottom: 20px;
  color: #333;
  text-align: justify;
  font-family: "Manrope", serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.documents .contentText a {
  text-decoration: none;
  color: blue;
}

.documents .contentText a:visited {
  color: blue;
}

.contentText ul {
  list-style-type: disc;
  padding-left: 40px;
  margin: 10px 0;
}

.contentText ul li {
  list-style-type: disc;
  margin-bottom: 5px;
  padding-left: 0;
}

.contentText ul li:before {
  content: "";
}

.documents .contentText ul li p {
  margin-bottom: 0;
}

.page-header:hover + .page-content .contentText {
  display: block !important;
}

@media (width<=1450px) {
  .page-content-mepagese {
    padding: 0 100px;
  }
}

@media (width<=1250px) {
  .page-content-mepagese {
    padding: 0 50px;
  }
}

@media (width<=992px) {
  .page-content-mepagese {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 20px;
  }

  .documents .contentText {
    width: fit-content;
    margin-left: 14px;
  }
}
