.grid-container {
  display: grid;
  grid-template-areas:
    ". item-1 ."
    "item-2 item-3 item-4"
    "item-5 item-6 item-7"
    "item-8 item-9 item-10"
    "item-11 item-12 item-13";
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
  padding: 0 130px;
  margin-top: 30px;
  box-sizing: border-box;
}

.grid-item {
  position: relative;
}

.grid-item.first-row {
  grid-column: 2 / 3;
  grid-row: 1;
}

.grid-item:not(.first-row) {
  grid-column: span 1;
}

.element {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  background-color: #2b8bb4;
  color: #fff;
  border-radius: 8px;
  white-space: normal;
  width: 100%;
  font-family: "Manrope", serif;
  font-size: 22px;
  line-height: 24px;
  position: relative;
  padding: 20px;
}

.content {
  grid-column: span 3;
  text-align: center;
  padding: 10px;
  margin-top: 5px;
}

.content ul {
  padding-left: 10px;
}

.content ul li {
  list-style-type: disc;
  text-align: left;
  margin-bottom: 5px;
  font-family: "Manrope", serif;
}

.content ul li strong {
  font-family: "Manrope", serif;
}

.content ul li a {
  text-decoration: underline;
  color: #0000ee;
  font-family: "Manrope", serif;
}

.content ul ul ul li {
  list-style-type: none;
}

.content .notes {
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  font-family: "Manrope", serif;
}

.grid-container {
  grid-auto-rows: auto;
}

.item-1 {
  grid-area: item-1;
}

.item-2 {
  grid-area: item-2;
}

.item-3 {
  grid-area: item-3;
}

.item-4 {
  grid-area: item-4;
}

.item-5 {
  grid-area: item-5;
}

.item-6 {
  grid-area: item-6;
}

.item-7 {
  grid-area: item-7;
}

.item-8 {
  grid-area: item-8;
}

.item-9 {
  grid-area: item-9;
}

.item-10 {
  grid-area: item-10;
}

.item-11 {
  grid-area: item-11;
}

.item-12 {
  grid-area: item-12;
}

.item-13 {
  grid-area: item-13;
}

.item-1 .element,
.item-2 .element,
.item-3 .element,
.item-4 .element {
  height: 120px;
}

.item-5 .element,
.item-6 .element,
.item-7 .element,
.item-8 .element,
.item-9 .element,
.item-10 .element,
.item-11 .element,
.item-12 .element,
.item-13 .element {
  height: 200px;
}

@media (width<=1450px) {
  .grid-container {
    padding: 0 100px;
  }
}

@media (width<=1250px) {
  .grid-container {
    padding: 0 50px;
  }
}

@media (width<=992px) {
  .grid-container {
    padding: 0 20px;
  }
}

@media (width<=805px) {
  .grid-container {
    gap: 15px;
  }

  .element {
    font-size: 18px;
  }

  .content {
    font-size: 14px;
  }
}

@media (width<=680px) {
  .grid-container {
    gap: 10px;
  }

  .element {
    font-size: 16px;
    padding: 10px;
  }

  .content {
    font-size: 14px;
  }
}

@media (width<=620px) {
  .grid-container {
    gap: 5px;
  }
}

@media (width<=550px) {
  .grid-container {
    gap: 5px;
  }

  .element {
    font-size: 14px;
    padding: 10px;
  }

  .content {
    font-size: 12px;
  }
}

@media (width<=490px) {
  .grid-container {
    padding: 0 10px;
  }
  .element {
    font-size: 14px;
    padding: 5px;
  }

  .content {
    font-size: 12px;
  }
}

@media (width<=450px) {
  .grid-container {
    padding: 0 10px;
  }
  .element {
    font-size: 12px;
    padding: 5px;
  }

  .content {
    font-size: 10px;
  }
}
