.ekspozita {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 32px;
  padding: 0 130px;
  box-sizing: border-box;
  overflow-x: hidden;
}

.ekspozita-item {
  display: inline-block;
  width: auto;
  text-align: center;
  padding: 10px;
  cursor: pointer;
}

.ekspozita-item img.ekspozita-image {
  width: 100%;
  max-width: 200px;
}

.ekspozita-item .ekspozita-text {
  color: #000;
  font-family: "Lexend", serif;
  font-size: 18px;
  text-decoration: none;
  margin-top: 8px;
  white-space: nowrap;
}

.ekspozita-item .ekspozita-text p {
  margin: 0;
  padding: 0 10px;
}

.ekspozita-page-title {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: #333;
  padding: 50px 130px;
}

.ekspozita-page-subtitle {
  width: 100%;
  color: #fff;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 5px;
  font-family: "Manrope", serif;
  font-size: 18px;
  font-weight: 200;
}

.ekspozita-item-title {
  width: 100%;
  color: #fff;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Manrope", serif;
  font-size: 40px;
}

.ekspozita-item-images {
  width: 100%;
  padding: 30px 130px;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  justify-content: center;
}

.ekspozita-item-image {
  margin: 15px;
}

.ekspozita-item-image img {
  vertical-align: middle;
  max-width: 100%;
  display: inline-block;
  cursor: pointer;
  object-fit: cover;
}

.modal-post.ekspozita .modal-content-post.ekspozita {
  max-height: 100%;
}

.modal-post.ekspozita .modal-content-post.ekspozita img {
  height: 90vh;
  max-width: 100%;
  padding: 0;
}

.objekte-muzeale {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  margin-top: 40px;
}

.objekt-muzeal {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.objekt-muzeal img {
  width: 40%;
}

.objekt-muzeal .objekt-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
}

.objekt-muzeal .objekt-titulli {
  font-size: 22px;
  color: #333;
  font-weight: 600;
  font-family: "Lexend", serif;
  line-height: 24px;
  text-align: left;
}

.objekt-muzeal .objekt-pershkrimi {
  max-width: 500px;
  font-size: 18px;
  font-family: "Manrope", serif;
  line-height: 24px;
  text-align: left;
}

@media (width<=1450px) {
  .ekspozita {
    padding: 0 100px;
  }

  .ekspozita-page-title {
    padding: 50px 50px;
  }

  .ekspozita-item-images {
    padding: 30px 50px;
  }
}

@media (width<=1250px) {
  .ekspozita {
    padding: 0 50px;
  }
}

@media (width<=1100px) {
  .ekspozita-item-images {
    grid-template-columns: 33.3% 33.3% 33.3%;
  }

  .objekte-muzeale {
    padding: 0 20px;
  }

  .objekt-muzeal {
    gap: 20px;
  }

  .objekt-muzeal img {
    width: 460px;
  }
}

@media (width<=992px) {
  .ekspozita {
    padding: 0 20px;
  }

  .ekspozita-page-title {
    padding: 50px 20px;
  }

  .ekspozita-item-images {
    padding: 30px 20px;
  }

  .ekspozita-item .ekspozita-text {
    white-space: normal;
  }
}

@media (width<=950px) {
  .objekt-muzeal img {
    width: 370px;
  }
}

@media (width<=860px) {
  .ekspozita-item-images {
    grid-template-columns: 50% 50%;
  }
}

@media (width<=700px) {
  .objekt-muzeal img {
    width: 270px;
  }
}

@media (width<=600px) {
  .ekspozita-item-images {
    grid-template-columns: 100%;
  }

  .objekt-muzeal {
    flex-direction: column;
    padding-bottom: 15px;
    border-bottom: 1px solid #efefef;
  }

  .objekt-muzeal:last-child {
    border: none;
  }
}

@media (height<=950px) and (width<=675px) {
  .modal-post.ekspozita .modal-content-post.ekspozita img {
    height: 70vh;
  }
}

@media (height<=950px) and (width<=570px) {
  .modal-post.ekspozita .modal-content-post.ekspozita img {
    height: 55vh;
  }
}

@media (height<=950px) and (width<=500px) {
  .modal-post.ekspozita .modal-content-post.ekspozita img {
    height: 40vh;
  }
}
